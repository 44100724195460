import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>Privacy Policy</h1>
    <Link to="/">Return to homepage</Link>
    <p>Date of Last Update: February 1, 2022</p>
    <h2>Introduction and Purpose</h2>
    <p> Cairnz d.b.a as 1UP Ventures, LLC. is committed to protecting privacy. We have prepared this privacy policy (this “Privacy Policy”) to describe our practices regarding the Personal Data (as defined below) and other information Cairnz and its affiliates (“we,” “us,” “our,” or “CAIRNZ”), collect from users of our website located at cairnz.com and in connection with our solutions (e.g., products) and services (the "Products"). This policy also informs how that information collected may be used, with whom it may be shared, and the options you have to control the dissemination and use of your information.  In addition, this Privacy Policy tells you about your privacy rights and how the law protects you.
    This Privacy Policy, together with our Terms of Use constitute a single, binding agreement between you and Cairnz (this “Agreement”). By using or accessing the website and associated domains of  http://www.cairnz.com (the “Website”), you accept the practices set forth in this Privacy Policy and agree to be bound by this Agreement. This website is not intended for children and we do not knowingly collect data relating to children.
    It is important that you read this Privacy Policy together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This Privacy Policy supplements the other notices and is not intended to override them
    BY USING THE WEBSITE, YOU CONSENT TO THE COLLECTION AND PROCESSING OF PERSONAL DATA DESCRIBED IN THIS AGREEMENT.
    IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY OR THE REST OF THIS AGREEMENT, THEN YOU MUST NOT ACCESS OR USE THE WEBSITE.</p>
    <h2>Type of Data We Collect</h2>
      <p>We collect Personal Data and Anonymous Data from you when you visit our site, when you send us information or communications, when you download and use our Products, and when you register for white papers, web seminars, and other events hosted by us. "Personal Data" means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data. Personal Data or PII excludes information that has been aggregated and/or anonymized so that it does not allow a third party to easily identify a specific individual (such excluded information is called “non-PD”).  "Anonymous Data" means data that is not associated with or linked to your Personal Data; Anonymous Data does not permit the identification of individual persons. We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data).</p>
    <h3>Personal Data You Provide to Us</h3>
      <p> We collect Personal Data from youvia web or mobile telemetry, download and install the Products, create an account to log in to our network, or sign-up for our newsletter or other marketing material. When you order Products on our website, we will collect all information necessary to complete the transaction, including your name, credit card information, billing information and shipping information. We also retain information on your behalf, such as files and messages that you store using your account. If you provide us feedback or contact us via e-mail or submit a response to an employment opportunity posted on our website, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply, and any information that you submit to us, such as a resume. When you participate in one of our surveys, we may collect additional profile information. When you post messages on the message boards of our website, the information contained in your posting will be stored on our servers and other users will be able to see it. We also collect other types of Personal Data that you provide to us voluntarily, such as operating system and version, Product version numbers, and other requested information if you contact us via e-mail regarding support for the Products. We may also collect Personal Data, such as demographic information, from you via the Products or at other points in our website that state that Personal Data is being collected.</p>
     <h3>Personal Data Collected Via Technology</h3>
      <p>To make our website and Products more useful to you, our servers (which may be hosted by a third party service provider) collect Personal Data from you, including browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit.</p>
     <h3>Personal Data Collected Via Cookies</h3>
      <p>We also use Cookies (as defined below) and navigational data like Uniform Resource Locators (URL) to gather information regarding the date and time of your visit and the solutions and information for which you searched and which you viewed. Like most technology companies, we automatically gather this Personal Data and store it in log files each time you visit our website or access your account on our network. "Cookies" are small pieces of information that a website sends to your computer’s hard drive while you are viewing a web site. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our website. Persistent Cookies can be removed by following Internet browser help file directions. You may choose to refuse or disable Cookies via the settings on your browser, however by doing so, some areas of our website may not work properly.</p>
    <h3>Personal Data That We Collect From You About Others</h3>
      <p>If you decide to create an account for and invite a third party to join our network, we will collect your and the third party's names and e-mail addresses in order to send an e-mail and follow up with the third party. You or the third party may contact us to request the removal of this information from our database.</p>
    <h3>Creation of Anonymous Data</h3>
      <p> We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request, usage patterns (including transactions), fences, etc. so that we may enhance the content of our Products and improve site navigation, features and functionality on platform including machine learning and artificial intelligence engines, and for marketing and analytics. We reserve the right to use and disclose Anonymous Data to Third Party Companies at our discretion while ensuring adequate and no harm may come to you.</p>
    <h3>Information we may collect automatically</h3>
      <p>  When you use or access the Website or Application, we automatically collect and store certain information about your computer or mobile device and your activities. This information includes:
    (1)	Device ID. Your computer or mobile device's unique ID number.  In case of mobile (e.g., IMEI).
    (2)	Geographic Location. Your computer’s or device's geographic location and timezone when you first use or access the Website.
    (3)	Device Information/Specifications. Technical information about your computer or mobile device (e.g., type of device, web browser or operating system, IP address and internet service provider) to analyze trends, administer the Website, prevent fraud, track visitor movement in the aggregate, and gather broad demographic information.
    (4)	Length and Extent of Usage.  How long you used the Website and which features you used.
    (5)	Information obtained through Cookies. We use Cookies to keep track of some types of information while you are visiting and using the Website. “Cookies” are very small files placed on your computer, and they allow us to count the number of visitors to our Website and distinguish repeat visitors from new visitors. They also allow us to save user preferences and track user trends. We rely on Cookies for the proper operation of the Website; therefore if your browser is set to reject all Cookies, the Website may not function properly. Users who refuse Cookies assume all responsibility for any resulting loss of functionality with respect to the Website.
    (6)	Information obtained through web beacons. “Web Beacons” (also known as “clear gifs” and “pixel tags”) are small transparent graphic images that are often used in conjunction with Cookies in order to further personalize the Website for our users and to collect a limited set of information about our visitors including geographical location, length and extent of usage, and whether visitors have registered for any of the Website. We may also use Web Beacons in email communications in order to understand the behavior of our customers, such as whether an email has been opened or acted upon.
    (7)	Information obtained through transactions. “Transactions” (also known as “payments”, “orders”, “payment tokens”, “payment details”) are often used in conjunction with compliance and regulatory practices including PCI compliance and track and trace programs.  Data is often auto captured as an e-commerce transaction and further written and/or shared for relevant operations and optimizations of our software products.  We may also use Transactions in analytics in order to understand the behavior of users within an urban environment in order optimize user experience.</p>
    <h2>How We Use Your Information</h2>
    <p> General Purposes. Subject to the terms of this Privacy Policy, we may use the information we collect from you for the following purposes. In general, Personal Data you submit to us is used either to respond to requests that you make, or to aid us in serving you better. We use your Personal Data in the ways set out in the table below:
      (1) To register you as a new customer (if needed) and provide administration of our website and Products
      (2) To manage our relationship with you which will include: (a) Notifying you about changes to our terms or privacy policy; (b) Asking you to leave a review or take a survey
      (3) To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
      (4) To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
      (5) To use data analytics and engineering to improve our website, products/services, marketing, customer relationships and experiences
      (6) To meet or comply with regulatory statutes and compliance standards upon consent
      (7) To make suggestions and recommendations to you about products or services that may be of interest to you

      Email Addresses. We use your email address for both “administrative” (e.g., confirming your registration) and, with your consent, “promotional” (e.g., newsletters, new listings, special discounts, event notifications, special third-party offers) purposes. Email messages we send to you may contain code that enables our database to track your usage of the e-mails, including whether the email was opened and what links (if any) were clicked. You may opt-out of receiving promotional emails and other promotional communications from us at any time via the opt-out links provided in such communications, or by contacting us with your specific request. However, we reserve the right to send you certain communications relating to the Website (such as service announcements, security alerts, update notices, or other administrative messages) without affording you the opportunity to opt out (unless EU citizen) of receiving such communications.

      Use for Research. In addition to the uses outlined above, by using the Website and our products, you agree to allow us to anonymously use information from you and your interaction to continue our research into emerging technologies (e.g., artificial intelligence, blockchain), so that we may continue to improve the Website and Products for users and the general public. This research may be published in our blogs or interviews. However, all of your data will be kept anonymous, and no Personal Data will be published without your consent.</p>

    <h2>Sharing Your Information</h2>
    <p>The information we collect is used to improve the content and the quality of the Website, and without your consent we will not otherwise share your Personal Data with any other party(s) for commercial purposes except to provide and improve the Website, whenever we have your permission, or under the following circumstances:

    (1)	Public Information.  Public information is any information you share with a public audience, including information in your public online profiles, or content you share in a public forum or other public comments. Public information is available to anyone on or off our Website and can be seen or accessed through online search engines, APIs, and offline media.  If you publicly post information on the Website, that information will also be public.

    (2)	Service Providers. We may share your information with our third-party service providers that support various aspects of our business operations (e.g., analytics providers, security and technology providers, and payment processors).

    (3)	Business Transfers. We may transfer your information to another company in connection with a merger, corporate restructuring, sale of any or all of our assets, or in the event of bankruptcy.

    (4)	Aggregate Data. We may combine non-PD we collect with additional non-PD collected from other sources. We also may share aggregated, non-PD with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis.</p>

    <h2>Your Rights Your Choices</h2>
      <p>Cairnz strives, to the best of our ability, to abide by principles set forth within the California Consumer Privacy Act that is set to go into effect in January 2020.  At the time of this update, the United States has no national or federal privacy standard.
      Where applicable law requires (and subject to any relevant exceptions under such law), you may have the following rights:

      (1)	The right to know what personal information is being collected about them.
      (2)	The right know whether personal information is sold or disclosed and to whom.
      (3)	The right to say no to the sale of personal information
      (4)	The right to access personal information
      (5)	The right to equal service and price, even if you exercise the previously mentioned rights

      You can exercise such rights to your Personal Data by contacting us.

      You can also elect not to receive marketing communications by following the unsubscribe instructions in such communications.

      We may retain information for a commercially reasonable time for backup, archival, and audit purposes. If you want any Personal Data permanently deleted, you must request such deletion by contacting us.

      Additionally, if we rely on consent for the processing of your Personal Data, you have the right to withdraw it at any time. When you do so, this will not affect the lawfulness of any processing of your data that was completed before your consent withdrawal.</p>

    <h2>Third Party Sites</h2>
    <p>The Website may contain links to other websites. If you choose to click on a third party link, you will be directed to that third party's website. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own Cookies or other files on your computer, collect data or solicit Personal Data from you. Other websites follow different rules regarding the use or disclosure of the Personal Data you submit to them. We encourage you to read the privacy policies or statements of the other websites you visit.</p>
    <h2>How We Respond To Legal Requests or Prevent Harm</h2>
    <p>We may access, preserve and share your information without notice or consent from you in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the United States where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; or to prevent death or imminent bodily harm. For example, we may provide information to third-party partners about the reliability of your account to prevent fraud and abuse on and off of the Website. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.</p>
    <h2>Children's Privacy</h2>
      <p>Only individuals over the age of 21 are eligible to use the Website. However, we pay particular attention to children’s privacy. The Website is not directed to children under the age of 13 and we do not knowingly collect, maintain or use Personal Data from children under the age of 13. If you learn that your child has provided us with Personal Data, you may alert us through our contact form. Should we learn that a child under 13 has provided us with Personal Data, we will delete that information from our database.</p>
    <h2>Securing Your Personal Data</h2>
    <h3>Security Measures</h3>
    <p> Unauthorized entry or use, hardware or software failure, the inherent insecurity of the Internet and other factors, may compromise the security of your Personal Data at any time. Nevertheless, we strive to safeguard Personal Data to ensure that information is kept private and secure at all times. We maintain administrative, technical and physical safeguards that are intended to appropriately protect against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse and any other unlawful form of processing of, the Personal Data in our possession. We employ security measures such as using firewalls to protect against intruders, building redundancies throughout our network (so that if one server goes down, another can cover for it) and testing for and protecting against network vulnerabilities. </p>
    <h3>Data Retention</h3>
      <p> We retain Personal Data and Anonymized Data for as long as is necessary given the purposes for which it was originally collected. We also retain Personal Data for as long as necessary to achieve the purposes described in this Privacy Policy, for example, to comply with our legal obligations, to protect us in the event of disputes and to enforce our agreements and to protect our and others’ interests.  We retain Anonymized Data for as long as necessary to ensure optimal performance of our products and associated services to our customers and partners.The precise periods for which we keep your Personal Data vary depending on the nature of the information and why we need it including requirements for machine learning and artificial intelligence engine optimization and meeting local, national, and international compliance and regulatory standards for industries and organizations using our solutions (e.g., products, services). Factors we consider in determining these periods include the minimum required retention period prescribed by law or recommended as best practice, the period during which a claim can be made with respect to an agreement or other matter, whether the Personal Data has been aggregated or pseudonymized, and other relevant criteria. For example, the period we keep your email address is related to how you have interacted with our previous emails, while the period for which we keep a support message is related to how long has passed since the last submission in the thread.</p>
    <h2>Using Our Services from Outside the United States</h2>
    <h3>Data Transfers</h3>
      <p>If you are visiting the Website from outside the United States, please be aware that your Personal Data may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. By using the Website, you understand and agree that your information may be transferred to our facilities and those third parties with whom we share it as described in this Privacy Policy.</p>
    <h3>Privacy Standards for Data Transfer</h3>
    <h4>For US Based Organizations and Users Only</h4>
      <p>Your Personal Data may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of Personal Data may be less stringent than the laws in your country. Cairnz adheres to the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework (collectively, "Privacy Shield") as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union, Switzerland, and the United Kingdom to the United States in reliance on Privacy Shield. In addition, for US based customers and users, in relation to data transfers and management, Cairnz aligns our data efforts to principles set forth within the California Consumer Privacy Act aimed to go into effect in January 2020. You may direct any inquiries or complaints concerning our Privacy Shield compliance via our website. Cairnz will respond within 45 days. If we fail to respond within that time, or if our response does not address your concern, you may contact the International Centre for Dispute Resolution, the international division of the American Arbitration Association (ICDR/AAA), which provides an independent third-party dispute resolution body based in the United States, here. As noted above, Cairnz uses a limited number of third-party service providers to assist us in providing our services to customers. These third parties may access, process, or store personal data in the course of providing their services. Cairnz maintains contracts with these third parties restricting their access, use and disclosure of personal data, and Cairnz is not liable if they fail to meet those obligations and we are not responsible for the event giving rise to the damage.  Cairnz may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
      <h4>Transfers to Non-Affiliates.</h4>
        <p>In some cases we may transfer Personal Data or Anonymized Data to unaffiliated third-party data controllers. These third parties do not act as agents or service providers and are not performing functions on our behalf. We may transfer your Personal Data to third-party data controllers for the purposes described in this Privacy Policy. We will only provide your Personal Data to third-party data controllers where you have not opted-out of such disclosures, or in the case of sensitive Personal Data, where you have opted-in if the Privacy Shield requires consent. We enter into written contracts with any unaffiliated third-party data controllers requiring them to provide the same level of protection for Personal Data the Privacy Shield requires. We also limit their use of your Personal Data so that it is consistent with any consent you have provided and with the notices you have received.</p>
      <h4>For European Organizations and Users Only</h4>
      <p>For European based organizations and users only, we conduct our data transfers in accordance with Chapter V of the European General Data Protection Regulation (the “GDPR”). Specifically, we conduct data transfers subject to the following standards:
      (1)	Privacy Shield. We transfer, in accordance with Article 45 of the GDPR, Personal Data to companies that have certified their compliance with the EU-U.S. or Swiss-U.S. Privacy Shield Frameworks (each individually and jointly, the "Privacy Shield").
      (2)	Standard data protection clauses. We may, in accordance with Article 46 of the GDPR, transfer Personal Data to recipients that have entered into a European Commission approved contract for the transfer of personal data outside the European Economic Area.
      (3)	Other means. We may, in accordance with Articles 45 and 46 of the GDPR, transfer Personal Data to recipients that offer adequate levels of data protection as evidenced by: (a) location in a country or membership in an organization that the European Commission or other appropriate supervisory authority has confirmed, by decision, offers an adequate level of data protection, (b) certification through an approved certification mechanism pursuant to Article 42 of the GDPR, (c) adherence to binding corporate rules adopted pursuant to Article 47 of the GDPR, or (d) any other mechanism that may demonstrate such adequacy under the GDPR.
      (4)	For EU citizens, in alignment to GDPR, we will respond to inquiries or questions regarding our privacy policy or data collected within 30 days.</p>
    <h2>Changes to this Policy</h2>
      <p>We may update this Privacy Policy from time to time at our sole discretion. When we post changes to this Privacy Policy, we will revise the "last updated" date at the top of this Privacy Policy. We recommend that you check our Website from time to time to inform yourself of any changes in this Privacy Policy or any of our other policies.</p>
    <h2>How to Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or legal inquiries regarding privacy, please contact us via our website.</p>
  </Layout>
)

export default SecondPage
